<template>
    <div class="main">
        <div class="card-title">
            <h3>System users</h3>
            <div>Create and manage users for this TallyPoint system</div>
        </div>


        <section class="card">
            <form @submit.prevent="">
                <a 
                    v-if="!newUser.showForm"
                    @click="newUser.showForm=true"
                    class="no-underline cursor-pointer"
                >Create a new system user</a>

                <div v-else class="bg-neutral-200 p-4">
                    <label class="label-block">
                        <span>Username (letters and numbers only):</span>
                        <div><input v-model="newUser.username" class="w-60"/></div>
                    </label> <br/>

                    <label class="label-block">
                        <span>Password:</span>
                        <div><input v-model="newUser.password" class="w-60"/></div>
                    </label>

                    <div>
                        <button @click="newUser.showForm=false" class="btn-danger">Cancel</button>
                        <button @click="addUser" class="btn-success">Save new user</button>
                    </div>
                </div>
            </form>

            <form @submit.prevent="">
            <table class="w-full">
                <tr>
                    <th>Username</th>
                    <th>Created</th>
                    <th></th>
                </tr>

                <template v-for="user in users">
                <tr v-if="!editing[user.id]" :key="user.id">
                    <td>{{user.username}}</td>
                    <td>{{user.created_at}}</td>
                    <td><button @click="editUser(user)">Edit</button></td>
                </tr>
                <tr v-else :key="user.id + '-' + 'edit'" class="bg-neutral-200">
                    <td colspan="2">
                        <label>
                            <span>Username</span>
                            <div><input v-model="user.username" disabled class="w-60 cursor-not-allowed" /></div>
                        </label>
                        <label>
                            <span>New password</span>
                            <div><input v-model="editing[user.id].password" class="w-60" /></div>
                            <div v-if="editing[user.id].password"><label>{{passwordStrength(editing[user.id].password)}}</label></div>
                        </label>
                    </td>
                    <td>
                        <button @click="$delete(editing, user.id)" class="btn-danger mr-4 mb-4">Cancel</button>
                        <button @click="saveUser(user.id)" class="btn-success">Save</button>
                    </td>
                </tr>
                </template>
            </table>
            </form>
        </section>
    </div>
</template>

<style scoped>
    select, input, button {
        font-size: 0.9em;
    }
</style>

<script>

export default {
    data: function() {
        return {
            users: [],
            editing: {},
            newUser: {
                showForm: false,
                username: '',
                password: '',
            },
        };
    },
    mounted() {
        this.refresh();
    },
    destroyed() {
    },
    computed: {
    },
    methods: {
        async refresh() {
            let resp = await this.$api.get('system/manage/users');
            this.users = resp.users;
        },
        editUser(user) {
            this.$set(this.editing, user.id, {...user});
        },
        async saveUser(userId) {
            await this.$api.post('system/manage/users', {
                ...this.editing[userId]
            });
            // this.elections[electionId] = this.editingElections[electionId];

            await this.refresh();
            this.$delete(this.editing, userId);
        },

        async addUser() {
            let username = this.newUser.username.trim();
            let password = this.newUser.password.trim();

            let validChars = /^[a-z0-9]{3,}$/i;
            if (!username.match(validChars)) {
                return;
            }

            await this.$api.post('system/manage/adduser', {
                username,
                password,
            });

            await this.refresh();
            this.newUser.showForm = false;
        },

        async addTenant() {
            let host = this.newTenant.host.trim().toLowerCase();
            if (!host.match(/^[a-z]{4,}$/)) {
                return;
            }

            await this.$api.post('system/manage/addtenant', {
                host,
            });
            await this.refresh();
            this.newTenant.showForm = false;
            this.newTenant.host = '';
        },

        passwordStrength(password){
            let strongPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{12,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]{3,})(?=.{12,}))')
            let mediumPassword = new RegExp('((?=.*[^A-Za-z0-9])(?=.{12,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,}))|((?=.*[A-Z])(?=.*[0-9]{4,})(?=.{12,}))|((?=.*[a-z])(?=.*[0-9]{4,})(?=.{12,}))')

            if (strongPassword.test(password)){
                return "strong"
            } else if (mediumPassword.test(password)){
                return "medium"
            } else {
                return "weak"
            }
        },
    },
};

</script>  