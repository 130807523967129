<template>
    <div class="main">
        <div class="card-title">
            <h3>Manage elections</h3>
            <div>Create and manage elections for tenants</div>
        </div>


        <section class="card">
            <form @submit.prevent="">
                <a 
                    v-if="!newTenant.showForm"
                    @click="newTenant.showForm=true"
                    class="no-underline cursor-pointer"
                >Create a new tenant</a>

                <div v-else class="bg-neutral-200 p-4 flex flex-col">
                    <label class="label-block">
                        <span>Tenant host (letters only, usually the customer code):</span>
                        <input v-model="newTenant.host" class="w-30" pattern="[a-z0-9]{4,}" />
                        <span v-if="newTenant.errors.host" class=" text-red-600 italic">Error with host. Must be letters only. Cannot be "uat" or "tallypoint".</span>
                    </label>

                    <label class="label-block">
                        <span>Public Site Path (letters, dashes and underscores only. Usually the name of the state e.g. virginia):</span>
                        <input v-model="newTenant.public_site_path" class="w-30"/>
                        <span v-if="newTenant.errors.public_site_path"  class=" text-red-600 italic">Error with Public Site Path. Path must be letters, and may include dashes and underscores.</span>
                    </label>

                    <div class="block">
                        <button @click="newTenant.showForm=false" class="btn-danger mr-4">Cancel</button>
                        <button @click="addTenant" class="btn-success">Save new tenant</button>
                    </div>
                </div>
            </form>

            <form @submit.prevent="">
            <table class="w-full">
                <tr>
                    <th></th>
                    <th>Status</th>
                    <th>Auth level</th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>

                <tbody v-for="tenant in tenants" :key="tenant.id">
                <tr class="">
                    <td colspan=6>
                        <div class="border-b border-neutral-600">
                            <h4 class="inline-block mb-0">{{tenant.host}}</h4>
                            <h4 class="inline-block mb-0 pl-10">{{tenant.public_site_path}}</h4>
                            <h4 class="inline-block mb-0 pl-10"><a :href="'https://'+tenant.domain" target="_blank" class="text-sm ml-4 no-underline">{{tenant.domain}}</a></h4>
                        </div>
                    </td>
                </tr>
                <template v-for="electionId in tenant.elections">
                <tr v-if="!editingElections[electionId]" :key="tenant.id + '-' + electionId">
                    <td>{{elections[electionId].location_name}}, {{elections[electionId].name}}</td>
                    <td>{{elections[electionId].status}}</td>
                    <td>{{elections[electionId].auth_level}}</td>
                    <td>{{elections[electionId].active ? 'Active' : ''}}</td>
                    <td><button @click="downloadSpreadsheet(electionId)">Download Setup Spreadsheet</button></td>
                    <td><button @click="editElection(electionId)">Edit election</button></td>
                </tr>
                <template v-else>
                <tr :key="tenant.id + '-' + electionId + 'editrow1'" class="bg-neutral-200">
                    <td colspan="3">
                        <label>
                            <span>Location name</span>
                            <input v-model="editingElections[electionId].location_name" />
                        </label>
                        
                        <label>
                            <span>Election name</span>
                            <input v-model="editingElections[electionId].name" />
                        </label>
                    </td>
                    <td colspan="2">
                        <label>
                            <span>Election status</span>
                            <select v-model="editingElections[electionId].status">
                                <option value="open">Open</option>
                                <option value="closed">Closed (Can enter results)</option>
                                <option value="completed">Completed (Cannot enter results)</option>
                            </select>
                        </label>

                        <label>
                            <span>Auth level</span>
                            <select v-model="editingElections[electionId].auth_level">
                                <option value="accept_all">Login from anywhere, on any device</option>
                                <option value="known_device">Login from anywhere, on only trusted devices</option>
                                <option value="known_device_location">Login from known locations, on only trusted devices</option>
                            </select>
                        </label>

                        <label v-if="!elections[electionId].active">
                            <span></span>
                            <div>
                                <input type="checkbox" v-if="tenant.default_election !== electionId" v-model="editingElections[electionId].active">
                                Set as the active election
                            </div>
                        </label>
                    </td>
                    <td>
                        <div class="mt-2">
                            <button @click="$delete(editingElections, electionId)" class="btn-danger mr-4 mb-4">Cancel</button>
                            <button @click="saveElection(electionId)" class="btn-success">Save</button>
                        </div>
                    </td>
                </tr>
                <tr :key="tenant.id + '-' + electionId + 'editrow2'" >
                    <td colspan="6" class="bg-neutral-200">
                        <div class="flex gap-10">
                            <div class="bg-white p-4 flex-grow">
                                <b>Setup demo election</b>
                                <p>
                                    This will erase all existing election data and import a demo election. Useful for demo accounts.
                                </p>
                                <p v-if="!toggles['importdemo'+electionId]">
                                    <a @click="$set(toggles, 'importdemo'+electionId, true)" class="cursor-pointer">Import demo election data</a>
                                </p>
                                <div v-else>
                                    
                                    <p><b>Think twice: Permanently remove existing election data for {{elections[electionId].location_name}}, {{elections[electionId].name}}? This cannot be reversed!</b></p>
                                    <div>
                                        <div>
                                            <b>Choose the size of the demo election:</b>
                                            <select v-model="selectedSize">
                                                <option value="small">Small</option>
                                                <option value="medium" default>Medium</option>
                                                <option value="large">Large</option>
                                            </select>
                                        </div>

                                        <button @click="importDemoElection(electionId, selectedSize)" class="btn-danger mr-4">Erase all data and import demo data</button>
                                        <a @click="$delete(toggles, 'importdemo'+electionId)" class="cursor-pointer">Cancel</a>
                                    </div>
                                </div>
                            </div>

                            <div class="bg-white p-4 flex-grow">
                                <b>Performance testing</b>
                                <p>
                                    This will be run off the existing imported spreadsheet data. You will need to wipe the election and reimport the spreadhseet afterwards to delete the test data.
                                </p>

                                <div v-if="activeJobs.includes(electionId) && stats[electionId]">
                                    <div v-if="!stats[electionId].bot_responses">
                                        <h3>Test is booting up ...</h3>   
                                    </div>
                                    <div v-else-if="stats[electionId].bot_responses" class="status-container">
                                        <div class="status-box" style="background-color: rgba(255, 2, 2, 0.5);">
                                            <p>Error</p>
                                            <p>{{ stats[electionId].bot_responses.error }}</p>
                                        </div>
                                        <div class="status-box" style="background-color: rgba(255, 238, 0, 0.5);">
                                            <p>Booting</p>
                                            <p>{{ stats[electionId].bot_responses.booting }}</p>
                                        </div>
                                        <div class="status-box" style="background-color: rgba(0, 0, 168, 0.5);">
                                            <p>Working</p>
                                            <p>{{ stats[electionId].bot_responses.in_progress }}</p>
                                        </div>
                                        <div class="status-box" style="background-color: rgba(0, 128, 0, 0.5);">
                                            <p>Completed</p>
                                            <p>{{ stats[electionId].bot_responses.completed }}</p>
                                        </div>
                                    </div>
                                    <br>
                                </div>

                                <div class="flex">
                                    <div class="w-1/2">
                                        <div v-if="!toggles['performancetesting'+electionId] && !activeJobs.includes(electionId)">
                                            <button @click="$set(toggles, 'performancetesting'+electionId, true)" class="w-full cursor-pointer">Show Testing Options</button>
                                        </div>
                                        <div v-if="toggles['performancetesting'+electionId]">
                                            <button @click="$delete(toggles, 'performancetesting'+electionId)" class="w-full cursor-pointer">Hide Testing Options</button>
                                        </div>
                                        <div v-if="activeJobs.includes(electionId) && !toggles['cancelperformancetesting'+electionId]">
                                            <button @click="$set(toggles, 'cancelperformancetesting'+electionId, true)" class="w-full cursor-pointer">Cancel Performance Test</button>
                                        </div>
                                        <div v-if="toggles['cancelperformancetesting'+electionId]">
                                            <button @click="cancelPerformanceTest(electionId)" class="btn-danger w-full">Confirm Cancellation</button>
                                        </div>

                                    </div>

                                    <div class="w-1/2" v-if="activeJobs.includes(electionId)"> 
                                        <button v-if="stats[electionId].performance_stats" @click="{showPerformanceStats(electionId)}" class="w-full">
                                            {{ showStats.includes(electionId) ? 'Hide Performance Stats' : 'See Performance Stats' }}
                                        </button> 
                                        <button v-else class="w-full" disabled>
                                            No Performance Stats Found
                                        </button> 
                                    </div>
                                    <div class="w-1/2" v-else-if="stats[electionId]"> 
                                        <button v-if="stats[electionId].performance_stats" @click="{showPerformanceStats(electionId)}" class="w-full">
                                            {{ showStats.includes(electionId) ? 'Hide Performance Stats' : 'See Performance Stats' }}
                                        </button> 
                                    </div>
                                </div>



                                <table v-if="showStats.includes(electionId) && stats[electionId]">
                                    <thead>
                                        <tr>
                                            <th>User Group</th>
                                            <th>Metric</th>
                                            <th>Specific Metric</th>
                                            <th>Value</th>
                                            <th>Unit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="stat in stats[electionId].performance_stats.performance_stats" :key="stat.specificMetric">
                                            <td>{{ stat.userGroup }}</td>
                                            <td>{{ stat.metric }}</td>
                                            <td>{{ stat.specificMetric }}</td>
                                            <td>{{ stat.value }}</td>
                                            <td>{{ stat.unit }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                




                                <div v-if="toggles['performancetesting'+electionId]">
                                    
                                    <p><b>Think twice: Permanently editing existing election data for {{elections[electionId].location_name}}, {{elections[electionId].name}}? This cannot be reversed!</b></p>
                                    <div>
                                        <!-- <div class="flex flex-col items-start py-4">
                                            <b>Test Target:</b>
                                            <select v-model="selectedTestTarget">
                                                <option value="thisSite" selected>Current Site</option>
                                                <option value="local" selected>Local Site</option>
                                                <option value="uat">UAT</option>
                                            </select>
                                        </div> -->
                                        <div class="flex flex-col items-start pb-4">
                                            <b>Test Type:</b>
                                            <select v-model="selectedTestType">
                                                <option value="fullTest" selected>Full test</option>
                                                <option value="apiTest">API only test</option>
                                            </select>
                                        </div>
                                        <div class="flex flex-col items-start pb-4">
                                            <b>Test Duration (mins):</b>
                                            <input v-model="selectedLength">
                                        </div>
                                        <div class="flex flex-col items-start pb-4">
                                            <b>Num of Bots (Leave empty for max):</b>
                                            <input v-model="selectedNumBots">
                                        </div>

                                        <button @click="startPerformanceTest(electionId, selectedLength, selectedTestType, selectedNumBots, selectedTestTarget)" class="btn-danger mr-4">Start Performance Test</button>
                                    </div>
                                </div>
                            </div>

                            <div class="bg-white p-4 flex-grow">
                                <b>Add DemTech admin user</b>
                                <p>This will add a DemTech admin user to this election, allowing you log in as an election admin.</p>
                                <a @click="addAdminUser(electionId)" class="cursor-pointer">Add admin user to election</a>
                            </div>
                            
                            <div class="bg-white p-4 flex-grow">
                                <b>Remove this election</b>
                                <p>This will delete this election. Audits and associated user accounts for this election will not be deleted.</p>
                                <a @click="deleteElectionButtonPressed(electionId, elections[electionId].name, tenant.default_election)" class="cursor-pointer">Delete election</a>
                            </div>
                        </div>
                    </td>
                </tr>
                </template>
                <tr v-if="newElectionUser.electionId === electionId" :key="tenant.id + '-' + electionId + 'newadmin'">
                    <td colspan=5>
                        <div class="text-success-800 bg-success-300 border border-success-800 p-5 m-5 w-6/12 mx-auto text-center">
                                    
                            <p><b>New election admin user</b></p>
                            <div class="text-left w-min whitespace-nowrap mx-auto mt-4">
                                <p>
                                    Make note of this, it won't be shown again!
                                </p>
                                <p>
                                    Once this election has been made active, the admin user can login using the following:<br />
                                    Username: {{newElectionUser.username}}@{{newElectionUser.host}}<br />
                                    Password: {{newElectionUser.password}}
                                </p>
                                <p>
                                    <a @click="newElectionUser.electionId=0" class="no-underline cursor-pointer">Close</a>
                                </p>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr v-if="newElectionAdminUser.electionId === electionId" :key="tenant.id + '-' + electionId + 'newuser'">
                    <td colspan=5>
                        <div class="text-success-800 bg-success-300 border border-success-800 p-5 m-5 w-6/12 mx-auto text-center">
                                    
                            <p><b>New election admin user</b></p>
                            <div class="text-left w-min whitespace-nowrap mx-auto mt-4">
                                <p>
                                    Make note of this, it won't be shown again!
                                </p>
                                <p>
                                    You can log into the election using the following:<br />
                                    Username: {{newElectionAdminUser.username}}<br />
                                    Password: {{newElectionAdminUser.password}}
                                </p>
                                <p>
                                    <a @click="newElectionAdminUser.electionId=0" class="no-underline cursor-pointer">Close</a>
                                </p>
                            </div>
                        </div>
                    </td>
                </tr>
                </template>

                <tr v-if="newElection.tenantId === tenant.id" :key="tenant.id + '-newelection'">
                    <td colspan=5 class="bg-neutral-200">
                        <label>
                            <span>Location name:</span>
                            <div><input v-model="newElection.location" class="w-60" /></div>
                        </label>
                        <label>
                            <span>Election name:</span>
                            <div><input v-model="newElection.name" class="w-60" /></div>
                        </label>

                        <button @click="newElection.tenantId=0" class="btn-danger mr-4">Cancel</button>
                        <button @click="addElection" class="btn-success">Save new election</button>
                    </td>
                </tr>
                <tr v-else :key="tenant.id + '-tenantactions'">
                    <td colspan=5>
                        <a @click="newElection.tenantId=tenant.id" class="no-underline cursor-pointer">Create new election</a>
                    </td>
                </tr>
                </tbody>
            </table>
            </form>
        </section>
    </div>
</template>

<style scoped>
    select, input, button {
        font-size: 0.9em;
    }
    .status-container {
        display: flex;
        justify-content: space-between;
    }
    .status-box {
        flex: 1;
        /* margin: 10px; */
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
        color: black;
    }
</style>

<script>

import { sleep } from '../../libs/misc';

export default {
    data: function() {
        return {
            tenants: [],
            elections: {},
            editingElections: {},
            newElection: {
                tenantId: 0,
                name: '',
                location: '',
            },
            newElectionUser: {
                electionId: '',
                host: '',
                username: '',
                password: '',
            },
            newElectionAdminUser: {
                electionId: '',
                username: '',
                password: '',
            },
            newTenant: {
                showForm: false,
                host: '',
                public_site_path: '',
                errors: {
                    host: false,
                    public_site_path: false,
                },
            },
            toggles: {},
            selectedSize: 'medium',
            selectedLength: 10,
            selectedTestType: 'fullTest',
            selectedNumBots: null,
            selectedTestTarget: 'thisSite',
            activeJobs: [],
            testsLoading : [], // Using this for frontend logic to bridge the gap between tests that have just begun and not yet registered by the backend that they are in progress
            showStats: [],
            stats: {},
            intervalId: null,
        };
    },
    mounted() {
        this.refresh();
        this.getTestsAndStats();
    },
    destroyed() {
    },
    computed: {
    },
    methods: {
        showPerformanceStats(electionId){
            console.log("showPerformanceStats")
            console.log("this.stats :")
            console.log(this.stats)
            console.log("this.showStats :")
            console.log(this.showStats)


            const index = this.showStats.indexOf(electionId);
            if (index !== -1) {
                this.showStats.splice(index, 1);
            } else {
                this.showStats.push(electionId);
            }
        },
        async getTestsAndStats(){
            let arbitraryElectionId = Object.keys(this.elections)[0];       

            if (!arbitraryElectionId){
                arbitraryElectionId = 9999;
            }       

            let resp = await this.$api.post('system/manage/testsandstats', {
                id : arbitraryElectionId,
            });

            console.log(resp);

            if (resp.status === 200) {
                if (resp.data){
                    this.activeJobs = resp.data.activeJobs;
                    this.stats = resp.data.stats;

                    if (!this.intervalId && this.activeJobs.length > 0) {
                        this.intervalId = setInterval(this.getTestsAndStats, 3000);
                    }

                    return
                } else {
                    this.activeJobs = [];
                    this.stats = {};
                    return;
                }
            }
        },

        async startPerformanceTest(electionId, length, testType, selectedNumBots, selectedTestTarget) {
            if (length < 1) {
                alert("Length must be greater than 0");
                return;
            }
            
            let response = await this.$api.post('system/manage/startperformancetest', {
                id: electionId,
                length: length,
                testType: testType,
                numBots: selectedNumBots,
                testTarget: selectedTestTarget,
            });


            if (response.status === 200){
                this.activeJobs.push(electionId);
                this.$delete(this.toggles, 'performancetesting'+electionId);
                this.testsLoading.push(electionId);

                if (!this.intervalId) {
                    await sleep(3000).then(() => {
                        this.intervalId = setInterval(this.getTestsAndStats, 3000);
                    });
                }
            } else {
                alert("Error when starting performance test");
            }
            
        },

        async cancelPerformanceTest(electionId) {            
            
            let response = await this.$api.post('system/manage/cancelperformancetest', {
                id: electionId,
            });

            console.log(response);

            if (response.status === 200){
                this.testsLoading = this.testsLoading.filter(id => id !== electionId);
                this.activeJobs = this.activeJobs.filter(id => id !== electionId);
                this.$delete(this.toggles, 'cancelperformancetesting'+electionId);

                if (this.intervalId) {
                    clearInterval(this.intervalId);
                    this.intervalId = null;
                }
            } else {
                alert("Error when cancelling performance test");
            }
        },

        async refresh() {
            let resp = await this.$api.get('system/manage');
            this.elections = resp.elections;
            this.tenants = resp.tenants;
        },
        editElection(electionId) {
            this.$set(this.editingElections, electionId, {...this.elections[electionId]});
        },
        async saveElection(electionId) {
            let updateElectionResp = await this.$api.post('system/manage/updateelection', {
                ...this.editingElections[electionId]
            });

            if (updateElectionResp.oldElectionPublishJobs){
                if (confirm(`The election set as 'active' has been changed but the old active election had live published results. Do you want to publish the results of this newly active election instead?`)){
                    await this.$api.post('/system/export/publish_active_website',{
                        election : this.editingElections[electionId],
                        election_id : electionId,
                    });
                }
            }

            await this.refresh();
            this.$delete(this.editingElections, electionId);
        },

        async addElection() {
            let name = this.newElection.name.trim();
            let location = this.newElection.location.trim();

            let validChars = /^[a-z0-9 _\-,'"]{2,}$/i;
            if (!name.match(validChars)) {
                return;
            }

            if (!location.match(validChars)) {
                return;
            }

            let resp = await this.$api.post('system/manage/addelection', {
                tenant_id: this.newElection.tenantId,
                name: name,
                location_name: location,
            });

            await this.refresh();

            this.newElectionUser.electionId = resp.id;
            this.newElectionUser.username = resp.admin.username;
            this.newElectionUser.password = resp.admin.password;
            this.newElectionUser.host = this.tenants[this.newElection.tenantId]?.host;

            this.newElection.tenantId = 0;
        },

        async addTenant() {
            let host = this.newTenant.host.trim().toLowerCase();
            let public_site_path = this.newTenant.public_site_path.trim().toLowerCase();

            if (!host.match(/^[a-z]{4,}$/) || host === 'uat' || host === 'tallypoint') {
                this.newTenant.errors.host = true;
                return;
            }

            if (!public_site_path.match(/^[a-z-_]{2,}$/)) {
                this.newTenant.errors.public_site_path = true;
                return;
            }

            await this.$api.post('system/manage/addtenant', {
                host,
                public_site_path
            });
            await this.refresh();
            this.newTenant.showForm = false;
            this.newTenant.host = '';
            this.newTenant.public_site_path = '';
            this.newTenant.errors.host = false;
            this.newTenant.errors.public_site_path = false;

        },

        async importDemoElection(electionId, size) {
            await this.$api.post('system/manage/importelection', {
                id: electionId,
                template: 'demo',
                size: size,
            });
            
            await this.refresh();
            this.$delete(this.toggles, 'importdemo'+electionId);
        },

        async addAdminUser(electionId) {
            let resp = await this.$api.post('system/manage/addelectionuser', {
                election_id: electionId,
            });

            this.newElectionAdminUser.electionId = resp.id;
            this.newElectionAdminUser.username = resp.admin.username;
            this.newElectionAdminUser.password = resp.admin.password;

            await this.refresh();
        }, 

        async downloadSpreadsheet(electionId){

            try {
                let res = await this.$api.post('/system/manage/downloadimportspreadsheet/', {
                election_id: electionId,
            });

                if(res.status == 200 && res.filename){
                    this.triggerDownload(`manage/download/${res.filename}`);
                } else {
                    alert("Error when downloading spreadsheet");
                }
            } catch (error) {
                alert("No spreadsheet found for this election");
            }
        },

        deleteElectionButtonPressed(electionId, electionName, defaultElection) {
            if (electionId === defaultElection) {
                alert("You cannot delete an active election");
                return;
            } else if (confirm(`Are you sure you want to delete the election : ${electionName}?`)) {
                this.sendDeleteElectionRequest(electionId);
            }
        },

        async sendDeleteElectionRequest(electionId){
            await this.$api.post('system/manage/deleteelection', {
                election_id: electionId,
            });
            await this.refresh();
        },

        async triggerDownload(url) {
            const link = document.createElement('a');
            link.href = `/api/system/${url}`;
            link.download = true;  // This prompts the user to save the file
            link.click();
        }
    },
};

</script>