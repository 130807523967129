<template>
    <div class="main" id="target-me">
        <div class="card-title">
            <h3>Audit Logs</h3>
            <div>Audit logs from all customers</div>
        </div>

        <section class="card">
            <div class="flex flex-row justify-between pb-5">
                
                <div class="flex gap-5">

                    <!-- Filter by Tenant -->
                    <div class="border-neutral-500 rounded p-3 bg-neutral-600 filter-container" v-if="filterData.tenant">
                        <label>Filter Tenant:</label>
                        <input v-model="filterData.tenant" class="w-60 ml-4 pl-1 border border-gray-400 rounded" v-on:input="filterAuditLogs(filterData.tenant, `tenant`)"/>
                    </div>
                    <div class="border-neutral-500 rounded p-3 bg-neutral-200 filter-container" v-else>
                        <label>Filter Tenant:</label>
                        <input v-model="filterData.tenant" class="w-60 ml-4 pl-1 border border-gray-400 rounded" v-on:input="filterAuditLogs(filterData.tenant, `tenant`)"/>
                    </div>

                    <!-- Filter by Election_id -->
                    <div class='border-neutral-500 rounded p-3 bg-neutral-600 filter-container' v-if="filterData.election_id">
                        <label class="d-flex">Filter Election ID:</label>
                        <input v-model="filterData.election_id" class="w-60 ml-4 pl-1 border border-gray-400 rounded" v-on:input="filterAuditLogs(filterData.election_id, `election_id`)"/>
                    </div>
                    <div class='border-neutral-500 rounded p-3 bg-neutral-200 filter-container' v-else>
                        <label class="d-flex">Filter Election ID:</label>
                        <input v-model="filterData.election_id" class="w-60 ml-4 pl-1 border border-gray-400 rounded" v-on:input="filterAuditLogs(filterData.election_id, `election_id`)"/>
                    </div>
                </div>

                <div class="flex gap-5 pl-5">
                    <!-- Single Refresh Button -->
                    <button type="button" :class="this.styling.colorChange" v-on:click="refreshLogs(`singleRefresh`)" title="Single Refresh">
                        <SvgSingleRefresh :class="this.styling.rotateSingle"/>
                    </button>

                    <!-- Auto Refresh Button -->
                    <button type="button" class="btn btn-default btn-sm rounded p-3 bg-neutral-600 border-neutral-500 lock" v-on:click="refreshLogs(`autoRefreshBoolean`)" title="Turn Auto Refresh Off" v-if="autoRefreshRunning" style="width:50px;">
                        <SvgAutoRefresh id="autoRefresh" :class="this.styling.rotateAuto" style="margin-left:2px;"/>

                    </button>
                    <button type="button" class="btn btn-default btn-sm border-neutral-500 rounded p-3 bg-neutral-200 hover:bg-neutral-600 lock" v-on:click="refreshLogs(`autoRefreshBoolean`)" v-else title="Turn Auto Refresh On" style="width:50px;">
                        <SvgAutoRefreshDisabled class="icon-unlock" id="autoRefreshDisabled"/>
                        <SvgAutoRefresh id="autoRefresh" class="icon-lock" style="padding-bottom:3px;"/>
                    </button>
                    
                    <!-- Number of Audits Displayed -->
                    <div class="border-neutral-500 rounded p-3 bg-neutral-200">
                        <label>Number Of Audits</label>
                        <select class="form-control ml-4 border border-gray-400 rounded" v-model="selectedLimit" v-on:change="numberOfLogsToDisplay(number.value)">
                            <option v-for="number in numberOfLogs" :value="number.value" :key="number.id">
                            {{ number.value }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>


            <table class="w-full table">
                <thead>
                    <tr class="">
                        <th class="bg-gray-200 sticky mt-2 top-0 text-left" width="10%">ID</th>
                        <th class="bg-gray-200 sticky top-0 text-left" width="15%">Created At</th>
                        <th class="bg-gray-200 sticky top-0 text-left" width="10%">Type</th>
                        <th class="bg-gray-200 sticky top-0 text-left" width="15%">Message</th>
                        <th class="bg-gray-200 sticky top-0 text-left" width="10%">Tenant</th>
                        <th class="bg-gray-200 sticky top-0 text-left" width="10%">Election ID</th>
                        <th class="bg-gray-200 sticky top-0 text-left max-w-[30%]" width="30%">Tags</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="singleAudit in computedObj" v-bind:key="singleAudit.id" :class='logStyling(singleAudit.id)' v-on:click="setCurrentLogSelected(singleAudit.id)" >
                        <td>
                            <h4 class="inline-block mb-0">{{singleAudit.id}}</h4>
                        </td>

                        <td>
                            <h4 class="inline-block mb-0">{{singleAudit["created_at"]}}</h4>
                        </td>

                        <td>
                            <h4 class="inline-block mb-0">{{singleAudit.type}}</h4>
                        </td>

                        <td>
                            <h4 class="inline-block mb-6" style="overflow-wrap: anywhere">{{singleAudit.message}}</h4>
                        </td>

                        <td>
                            <h4 class="inline-block mb-0" v-if="singleAudit.tags.tenant != undefined">{{singleAudit.tags.tenant}}</h4>
                            <h4 class="inline-block mb-0" v-else>N/A</h4>
                        </td>

                        <td>
                            <h4 class="inline-block mb-0">{{singleAudit.tags.election_id}}</h4>
                        </td>

                        <td>
                            <h4 class="inline-block mb-0 max-w-[30%]" style="overflow-wrap: anywhere">{{singleAudit.tags}}</h4>
                        </td>
                    </tr>
                    <tr class="">
                    </tr>
                </tbody>
            </table>

        </section>
    </div>
</template>

<style scoped>
table {
    table-layout:fixed;
    width:100%;
}

#autoRefresh{
    width:22px; height:22px; color:black; padding-right: 3px; padding-left:3px;
}

#autoRefreshDisabled{
    width:24px; height:24px; color:black;
}

.lock:hover .icon-unlock,
.lock .icon-lock {
    display: none;
}
.lock:hover .icon-lock {
    display: inline;
    
}

.dark-background{
    background-color: theme('colors.neutral.500');
}

.hover-background:hover{
    background-color: theme('colors.neutral.100');
}


.rotation-animation {
    -webkit-animation: rotation 1.5s 1 linear;
    -moz-animation: rotation 1.5s 1 linear;
    -o-animation: rotation 1.5s 1 linear;
    animation: rotation 1.5s 1 linear;
    transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
}

.rotation-animation-auto {
    -webkit-animation: rotation 1.5s 2 linear;
    -moz-animation: rotation 1.5s 2 linear;
    -o-animation: rotation 1.5s 2 linear;
    animation: rotation 1.5s 2 linear;
    transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
}

@-webkit-keyframes rotation {
    from {-webkit-transform: rotate(0deg);}
    to   {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
    from {-moz-transform: rotate(0deg);}
    to   {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
    from {-o-transform: rotate(0deg);}
    to   {-o-transform: rotate(359deg);}
}
@keyframes rotation {
    from {transform: rotate(0deg);}
    to   {transform: rotate(359deg);}
}

.single-refresh-animation{
    background-color: theme('colors.neutral.200');
    animation: FadeIn 2s ease-in-out forwards;
}

@keyframes FadeIn {
  0% {
    background-color: theme('colors.neutral.200');
  }
  70%{
    background-color: theme('colors.neutral.600');
  }
  100% {
    background-color: theme('colors.neutral.200');
  }
}


</style>

<script>
import _ from 'lodash';
import SvgSingleRefresh from '../../assets/svgs/singleRefresh.svg?inline';
import SvgAutoRefresh from '../../assets/svgs/autoRefresh.svg?inline';
import SvgAutoRefreshDisabled from '../../assets/svgs/autoRefreshDisabled.svg?inline';

export default {
    name: 'audits',
    
    components: {
        SvgSingleRefresh,
        SvgAutoRefresh,
        SvgAutoRefreshDisabled
    },

    computed:{
      computedObj(){
        return this.selectedLimit ? this.auditLogsData.slice(0,this.selectedLimit) : this.auditLogsData
      }
    },

    mounted(){
        // Creates timer for the auto refresh logic
        this.timer = setInterval(()=>{this.refreshLogs("autoRefreshInterval")}, 5000);
    },

    async created(){
        let apiResponse = await this.$api.get('system/audits');
        this.latestGetRequest =  'system/audits';
        this.auditLogsData=apiResponse.body;
    },

    data: function(){
        return {
            auditLogsData : [],
            orderKey: 'key', // precinct key by default
            orderDirection: 'asc',
            filterData : {tenant:"", election_id:""},
            numberOfLogs: {
                1: {id: 1, value: 50},
                2: {id: 2, value: 100},
                3: {id: 3, value: 200},
                4: {id: 4, value: 500},
            },
            currentLogSelected:null,
            selectedLimit:50, 
            autoRefreshRunning: false, 
            latestGetRequest: "",
            timer: '',
            styling: {rotateSingle: "", colorChange : "btn btn-default btn-sm border-neutral-500 rounded p-3 bg-neutral-200"}
        }
    },  

    methods: {        
        orderBy: _.orderBy,

        setCurrentLogSelected(id){
            if (this.currentLogSelected === id){
                this.currentLogSelected = null;
            } else {
                this.currentLogSelected = id;
            }
        },

        logStyling(audit){
            if (audit === this.currentLogSelected){
                return "dark-background";
            } else {
                return "hover-background";
            }
        },

        frontendFormValidation(userInput, extraAllowedCharacters, minimumCharacters){

            userInput = userInput.trim();

            if (extraAllowedCharacters == undefined){
                extraAllowedCharacters = "";
            }

            if (minimumCharacters !== undefined){
                minimumCharacters = `{${minimumCharacters},}`
            } else {
                minimumCharacters = "";
            }

            let validChars = `/^[^a-z0-9${extraAllowedCharacters}]${minimumCharacters}$/i`;

            console.log("validChars:")
            console.log(validChars);
            
            console.log(userInput.match(validChars));

            if (!userInput.match(validChars)) {
                console.log("failed")
                console.log(userInput)
                return userInput;
            } else {
                console.log("success")
                console.log(userInput)
                return ;
            }
        },

        numberOfLogsToDisplay(maxNumber){
            this.selectedLimit=maxNumber;
        },

        async filterAuditLogs(filterInput, filterName) {

            // Filter input refers to the user input from any of the filter boxes 
            // Filter name refers to the name of the filter e.g. tenant / election_id
            this.filterData[filterName]=filterInput;
            const keyArray = [];

            // Function iterates through the keys for data.filterData to create keyArray
            // When adding new filter options remeber to add it to data.filterData
            Object.keys(this.filterData).forEach(function(key) {
                keyArray.push(key);
            });

            let numberOfInputsPopulated = 0;

            keyArray.map((elem) =>{
                if (this.filterData[elem].length > 0 ){
                    numberOfInputsPopulated++;
                }
            })

            // This will automatically reset to the default audit search if there is no user input
            if (numberOfInputsPopulated == 0){  
                let apiResponse = await this.$api.get('system/audits');
                this.latestGetRequest =  'system/audits';
                this.auditLogsData=apiResponse.body;

            } else if (numberOfInputsPopulated == 1){

                const inputKeys = Object.keys(this.filterData);
                let filterDataInJson;

                inputKeys.map((element,index)=>{
                    if (this.filterData[inputKeys[index]].length > 0){
                        filterDataInJson = JSON.stringify({1:{"filterInput": this.filterData[inputKeys[index]], "filterName":element}});
                    }
                })

                let apiResponse = await this.$api.get(`system/audits/filter/${filterDataInJson}`);
                this.latestGetRequest =  `system/audits/filter/${filterDataInJson}`;
                this.auditLogsData=apiResponse.body;

            } else {

                const inputKeys = Object.keys(this.filterData);

                let multipleFilterString;
                
                inputKeys.forEach((key, index) => {
                    if (index == 0){
                        multipleFilterString = `"${index+1}":{"filterInput" : "${this.filterData[key]}", "filterName" : "${key}"}`;
                    } else {
                        multipleFilterString += `, "${index+1}":{"filterInput": "${this.filterData[key]}", "filterName" : "${key}"}`;
                    }
                });

                let apiResponse = await this.$api.get(`system/audits/filter/{${multipleFilterString}}`);
                this.latestGetRequest = `system/audits/filter/{${multipleFilterString}}`;
                this.auditLogsData=apiResponse.body;

            }
        },

        async refreshLogs(refreshType){
            
            function delay(time) {
                return new Promise(resolve => setTimeout(resolve, time));
            }

            if (refreshType == "singleRefresh"){
                // Single GET request sent 
                let apiResponse = await this.$api.get(this.latestGetRequest);
                console.log(apiResponse.body);
                this.styling.rotateSingle = "rotation-animation";
                this.styling.colorChange = "btn btn-default btn-sm border-neutral-500 rounded p-3 bg-neutral-200 single-refresh-animation";

                
                delay(1500).then(()=>this.auditLogsData=apiResponse.body).then(()=>{this.styling.rotateSingle = "rotation-animation-disabled"}).then(()=>this.styling.colorChange 
                = "btn btn-default btn-sm border-neutral-500 rounded p-3 bg-neutral-200");
                

            } else if (refreshType == "autoRefreshBoolean"){
                // Auto refresh boolean is switched
                this.autoRefreshRunning = !this.autoRefreshRunning;

                // And if boolean is true then request sent
                if (this.autoRefreshRunning){
                    let apiResponse = await this.$api.get(this.latestGetRequest);
                    this.auditLogsData=apiResponse.body;

                    this.styling.rotateAuto = "rotation-animation-auto";
                    delay(1500).then(()=>{this.styling.gear = "rotation-animation-disabled"});
                }

            } else if (refreshType == "autoRefreshInterval" && this.autoRefreshRunning){
                // If auto refresh setInterval triggered and auto refresh boolean is true then request sent
                let apiResponse = await this.$api.get(this.latestGetRequest);
                this.auditLogsData=apiResponse.body;
            }
        }
    },

    destroyed () {
        // Removes setInterval auto refresh
        clearInterval(this.timer);
    }

}
</script>
